// @ts-nocheck
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, infiniteHits } from 'instantsearch.js/es/widgets';
import { history } from 'instantsearch.js/es/lib/routers';
import { currencyFormatter } from '../utils/numbers';
import { Offcanvas } from 'tw-elements';

const algoliaClient = algoliasearch('CBR292HQN9', 'c4ed6847b3604a5f74a783585aabe7e9');
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests);
  },
 }
const indexName = 'outercleproducts'

const search = instantsearch({
  indexName,
  searchClient,
  routing: {
    router: history({
      windowTitle(routeState) {

        const indexState = routeState[indexName] || {}

        if (!indexState.query) {
          return window.document.title;
        }

        return `clé tile - results for: ${indexState.query}`
      },
      createURL({ qsModule, location, routeState }) {
        const { origin, pathname, hash } = location;
        const indexState = routeState[indexName] || {};
        const queryString = qsModule.stringify(routeState[indexName]);

        if (!indexState.query) {
          return `${origin}${pathname}${hash}`;
        }

        return `${origin}${pathname}?${queryString}${hash}`;
      },
      parseURL({ qsModule, location }) {
        const { query } = qsModule.parse(location.search.slice(1));
        if (query) {
          document.addEventListener("DOMContentLoaded", function() {
            const searchEl = document.getElementById('search');
            const searchDrawerInstance = Offcanvas.getOrCreateInstance(searchEl)
            searchDrawerInstance.show();
          })
        }

        return {
          [indexName]: {
            query: query,
          }
        }
      }
    })
  } 
});

let timerId;

search.addWidgets([
  searchBox({
    container: "#searchbox",
    showReset: true,
    placeholder: 'type here',
    queryHook(query, refine) {
      clearTimeout(timerId);
      timerId = setTimeout(() => refine(query), 200);
    },
    cssClasses: {
      root: 'mb-4',
      form: 'flex gap-2 items-center',
      input: 'w-full p-2 border-b border-t-0 border-x-0 border-b-neutral-500 border-solid focus-visible:outline-0 [&::-webkit-search-cancel-button]:appearance-none',
    },
    templates: {
      submit({ cssClasses }, { html }) {
        return html`
          <span class="${cssClasses.submit}">
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0296 10.8691C9.87022 12.1449 8.1976 12.9458 6.33791 12.9458C2.83758 12.9458 0 10.1083 0 6.60793C0 3.1076 2.83758 0.27002 6.33791 0.27002C9.83824 0.27002 12.6758 3.1076 12.6758 6.60793C12.6758 7.88623 12.2974 9.07613 11.6464 10.0717L17.8257 16.251L17.1186 16.9581L11.0296 10.8691ZM6.33791 11.9458C9.28596 11.9458 11.6758 9.55598 11.6758 6.60793C11.6758 3.65988 9.28596 1.27002 6.33791 1.27002C3.38986 1.27002 1 3.65988 1 6.60793C1 9.55598 3.38986 11.9458 6.33791 11.9458Z" fill="black"></path>
          </svg>
          </span>
        `;
      },
      reset ({ cssClasses }, { html }) {
        return html`
          <span class="${cssClasses.reset}">clear</span>
        `
      },
    },
  }),

  infiniteHits({
    container: "#hits",
    cssClasses: {
      list: 'grid grid-cols-2 gap-4',
      loadMore: 'flex text-center my-8 mx-auto bg-neutral-700 border border-solid border-neutral-700 text-neutral-50 text-xs font-semibold py-2 px-3 uppercase cursor-pointer disabled:bg-white disabled:cursor-not-allowed disabled:border-neutral-300 disabled:text-gray-300',
      emptyRoot: 'flex items-center justify-center text-xl font-semibold font-serif',
    },
    templates: {
      item(hit, { html }) {
        let unit = hit.meta?.pim?.uom === "pallet" ? "sqft" : hit.meta?.pim?.uom;
        // format materials array as "item, item + last item"
        let materials = JSON.parse(hit.meta?.pim?.material)
        let formatMaterials = (materials) => materials.reduce((acc, item, index) => {

          if (index === 0) {
            return item;
          // Future option to add + as the last separator
          // } else if (index === materials.length - 1) {
          //   return `${acc} + ${item}`;
          } else {
            return `${acc}, ${item}`;
          }
        }, '')
        return html`
          <article class="mb-6">
            <a href="/products/${hit.handle}?sku=${hit.sku}" class="block relative">
              <img
                data-v-55ee86c9=""
                alt="${hit.title.split(' ~')[0]}"
                height="350"
                width="350"
                data-testid="productImage"
                class="aspect-square object-cover block w-full h-full"
                src="${hit.image}?height=250&width=250"
                lazy="loaded"
              />
           </a>
            <summary>
              <div class="flex justify-between items-baseline mt-1">
                <p class="my-0 font-semibold">${hit.meta?.pim?.colorway}</p>
                <p class="my-0 text-xs">${formatMaterials(materials)}</p>
              </div>
              <p class="mt-1 mb-0 text-xs font-semibold">${hit.meta?.pim?.collection}</p>
              <p class="mt-1 mb-0 text-xs">${hit.meta?.pim?.sub_collection}${hit.title.includes('bundle') ? "bundle" : null}</p>
          
              <div class="text-right border-solid border-0 border-t border-black mt-2 text-sm font-semibold">
                <p class="mt-2 mb-0 leading-none">
                  ${currencyFormatter.format(hit.meta?.pim?.uom != "piece" ? hit.meta?.pim?.price_per_unit : hit.price)}/${unit}
                </p>
              </div>
            </summary>
          </article>
        `;
      },
      showMoreText(data, { html }){
        return html`<p>load more</p>`
      }
    },
  })
]);

search.start();

document.addEventListener('shown.te.offcanvas', (e) => {
  if (e.target.id !== 'searchbox') return;
  document.querySelector('#searchbox input').focus();
})