function handleButtonClick(targetElement: HTMLElement, direction: 'prev' | 'next') {
    const containerWidth = targetElement.clientWidth;
    const slideWidth = targetElement.children[0]?.clientWidth;
    const container = targetElement.parentElement as HTMLElement;
    
    container.classList.add('is-interacted');

    if (!slideWidth) {
        return;
    }

    const scrollAmount = direction === 'next' ? slideWidth : -slideWidth;
    const remainingScrollWidth = (targetElement.scrollWidth - targetElement.scrollLeft) - targetElement.clientWidth;
    const isAtEnd = remainingScrollWidth <= 0

    // Scroll to the next slide with or without looping
    targetElement.scrollBy({ left: scrollAmount, behavior: 'smooth' });

    // If at the end and moving forward, smoothly loop back to the first slide
    if (isAtEnd && direction === 'next') {
        targetElement.scrollTo({ left: 0, behavior: 'smooth' });
    } else if (targetElement.scrollLeft <= 0 && direction === 'prev') {
        // If at the start and moving backward, smoothly loop back to the last slide
        targetElement.scrollTo({ left: targetElement.scrollWidth - containerWidth, behavior: 'smooth' });
    }
}

const prevButton = document.querySelector('[data-te-target="#carousel-prev"]');
const carouselElement = document.querySelector('#carousel');
const nextButton = document.querySelector('[data-te-target="#carousel-next"]');

function isHTMLElement(element: Element | null): element is HTMLElement {
    return element instanceof HTMLElement;
}

function updateButtonVisibility(): void {
    if (carouselElement && carouselElement.scrollWidth > carouselElement.clientWidth) {
        nextButton?.classList.remove('hidden');
    } else {
        nextButton?.classList.add('hidden');
    }
}

if (isHTMLElement(carouselElement) && isHTMLElement(prevButton) && isHTMLElement(nextButton)) {
    prevButton.addEventListener('click', () => handleButtonClick(carouselElement, 'prev'));
    nextButton.addEventListener('click', () => handleButtonClick(carouselElement, 'next'));

    // Add a resize listener to window to handle changes in layout
    window.addEventListener('resize', updateButtonVisibility);

    // Initial check to set the correct visibility of buttons
    updateButtonVisibility();
}